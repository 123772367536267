<template>
  <b-modal id="modal-courses" hide-footer scrollable>
    <template #modal-header>
      <h4>
        {{ data.id === null ? $t("courses.new") : $t("courses.edit") }}
      </h4>
    </template>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(onSubmit)">
        <div class="new-user-info">
          <b-row class="justify-content-md-center">
            <b-form-group
              class="col-md-12"
              :label="$t('courses.period')"
              label-for="education"
            >
              <ValidationProvider
                :name="$t('courses.period')"
                rules="required"
                v-slot="{ errors }"
              >
                <v-select
                  v-model="data.school_period_id"
                  :placeholder="$t('courses.period')"
                  :options="getPeriods"
                  label="name"
                  :class="errors.length > 0 ? ' is-invalid' : ''"
                >
                  <template #no-options="{}">
                    {{ $t("app.not-found") }}...
                  </template>
                </v-select>
                <div v-if="errors[0]">
                  <small class="text-danger">{{ errors[0] }}</small>
                </div>
              </ValidationProvider>
            </b-form-group>
            <b-form-group
              class="col-md-12"
              :label="$t('courses.grade')"
              label-for="education"
            >
              <ValidationProvider
                :name="$t('courses.grade')"
                rules="required"
                v-slot="{ errors }"
              >
                <v-select
                  v-model="data.grade_id"
                  :placeholder="$t('courses.grade')"
                  :options="getGrades"
                  label="name"
                  :class="errors.length > 0 ? ' is-invalid' : ''"
                >
                  <template #no-options="{}">
                    {{ $t("app.not-found") }}...
                  </template>
                </v-select>
                <div v-if="errors[0]">
                  <small class="text-danger">{{ errors[0] }}</small>
                </div>
              </ValidationProvider>
            </b-form-group>

            <b-form-group
              class="col-md-12"
              :label="$t('courses.name')"
              label-for="name"
            >
              <ValidationProvider
                :name="$t('courses.name')"
                rules="required"
                v-slot="{ errors }"
              >
                <b-form-input
                  v-model="data.name"
                  type="text"
                  :placeholder="$t('courses.name')"
                  :class="errors.length > 0 ? ' is-invalid' : ''"
                >
                </b-form-input>
                <div class="invalid-feedback">
                  <span>{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
            </b-form-group>
          </b-row>
          <hr />
          <b-button
            :disabled="coursesLoading"
            variant="success"
            class="float-right"
            type="submit"
          >
            <span v-if="coursesLoading">
              <b-spinner small type="grow"></b-spinner>
              {{ $t("app.loading") }}...
            </span>
            <span v-else>
              {{ $t("app.save") }}
            </span>
          </b-button>
          <b-button
            @click="$bvModal.hide('modal-courses')"
            class="float-right mr-1"
            variant="outline-danger"
            >{{ $t("app.cancel") }}</b-button
          >
        </div>
      </form>
    </ValidationObserver>
  </b-modal>
</template>
<script>
import { core } from "@/config/pluginInit";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "modalForm",
  mounted() {
    core.index();
  },
  data() {
    return {
      data: {
        id: null,
        name: null,
        school_period_id: null,
        grade_id: null,
        college_id: null,
      },
    };
  },
  methods: {
    ...mapActions({
      storeCourses: "storeCourses",
    }),
    async show(item) {
      this.reserForm();
      if (item) {
        this.data = {
          id: item.id,
          name: item.name,
          school_period_id: item.schoolPeriod,
          grade_id: item.grade,
        };
      } else {
        this.data.school_period_id = this.getPeriods.length
          ? this.getPeriods[0]
          : null;
      }
      this.$bvModal.show("modal-courses");
    },
    async onSubmit() {
      let payload = {
        id: this.data.id,
        name: this.data.name,
        school_period_id: this.data.school_period_id.id,
        grade_id: this.data.grade_id.id,
        college_id: this.collageAuthUser.id,
      };
      const resp = await this.storeCourses(payload);
      this.$bvModal.hide("modal-courses");
      this.$emit("refresh");
      core.showSnackbar(
        "success",
        !this.data.id
          ? this.$t("app.success-create")
          : this.$t("app.success-edit")
      );
      this.reserForm();
    },
    reserForm() {
      this.data = {
        id: null,
        name: null,
        school_period_id: null,
        grade_id: null,
        college_id: null,
      };
    },
  },
  computed: {
    ...mapGetters({
      coursesLoading: "coursesLoading",
      getPeriods: "getPeriods",
      getGrades: "getGrades",
      collageAuthUser: "collageAuthUser",
    }),
  },
};
</script>
